import revive_payload_client_ZkpiZYETkP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_encoding@0.1.13_eslint@9.19.0_e7nisfh3axovephypljfslg27q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Y88SyMpAnT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_encoding@0.1.13_eslint@9.19.0_e7nisfh3axovephypljfslg27q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vXWQFaEuYk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_encoding@0.1.13_eslint@9.19.0_e7nisfh3axovephypljfslg27q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ZUMx7Z5OH5 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4._wkxiwn52q5yu77ffwyvtpat4ji/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import sentry_client_Ub7jFNgAqV from "/opt/buildhome/repo/modules/sentry/runtime/sentry.client.ts";
import payload_client_jEND6yN7st from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_encoding@0.1.13_eslint@9.19.0_e7nisfh3axovephypljfslg27q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_gEFDhBZ7kU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_encoding@0.1.13_eslint@9.19.0_e7nisfh3axovephypljfslg27q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_PFRaM3sIv0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_encoding@0.1.13_eslint@9.19.0_e7nisfh3axovephypljfslg27q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_NU0TQLjtUX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_encoding@0.1.13_eslint@9.19.0_e7nisfh3axovephypljfslg27q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_qnLVdH8jUr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_encoding@0.1.13_eslint@9.19.0_e7nisfh3axovephypljfslg27q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_twgjBawLPc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2__aam2ltjcmn37pjammvxbljndyi/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_SoJdHw4sFz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2__aam2ltjcmn37pjammvxbljndyi/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_pQJrQMeEQW from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2__aam2ltjcmn37pjammvxbljndyi/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import umami_iN1DWXMIUx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-umami@2.6.7/node_modules/nuxt-umami/plugins/umami.ts";
import marker_io_client_3vX6FuptAi from "/opt/buildhome/repo/plugins/marker-io.client.ts";
import defaults_0FTmPpWEAU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2__aam2ltjcmn37pjammvxbljndyi/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_ZkpiZYETkP,
  unhead_Y88SyMpAnT,
  router_vXWQFaEuYk,
  _0_siteConfig_ZUMx7Z5OH5,
  sentry_client_Ub7jFNgAqV,
  payload_client_jEND6yN7st,
  navigation_repaint_client_gEFDhBZ7kU,
  check_outdated_build_client_PFRaM3sIv0,
  chunk_reload_client_NU0TQLjtUX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qnLVdH8jUr,
  siteConfig_twgjBawLPc,
  inferSeoMetaPlugin_SoJdHw4sFz,
  titles_pQJrQMeEQW,
  umami_iN1DWXMIUx,
  marker_io_client_3vX6FuptAi,
  defaults_0FTmPpWEAU
]